.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Global style that applies to everything */
body {
  /* Set up the gradient background */
  /* background: linear-gradient(180deg, #f5f7f5 0%, #e2e6e3 100%); */
  background-color: #eef0f4;
  /* Ensure it covers the entire viewport */
  min-height: 100vh;
  width: 100vw;

  /* Apply the background to the entire content */
  background-repeat: no-repeat;

  /* Fix the background to the viewport */
  background-attachment: fixed;

  /* Set the background size to cover the entire viewport */
  background-size: cover;

  margin: 0; /* Remove default margin */
  overflow-x: hidden;
}
