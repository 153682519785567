.map-container {
  height: 400px;
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
  display: none;
}
.marker {
  background-color: #4caf50;
  border: 1px solid blue;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 50%;
}
